class StoreLink {
  constructor (
    id = undefined,
    generatedBy,
    originalUrl,
    openInPdfViewer,
    totalNrOfClicks,
    nrOfUniqueClicks,
    excludedClickerIds,
    additionalInfo,
    createdAt
  ) {
    this.id = id
    this.generatedBy = generatedBy
    this.generatedByUser = null
    this.originalUrl = originalUrl
    this.openInPdfViewer = openInPdfViewer
    this.totalNrOfClicks = totalNrOfClicks
    this.nrOfUniqueClicks = nrOfUniqueClicks
    this.excludedClickerIds = excludedClickerIds
    this.additionalInfo = additionalInfo
    this.createdAt = createdAt
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // generatedBy is read-only
      originalUrl: this.originalUrl,
      openInPdfViewer: this.openInPdfViewer
      // totalNrOfClicks is read-only
      // nrOfUniqueClicks is read-only
      // excludedClickerIds: this.excludedClickerIds
      // additionalInfo is read-only
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    generatedBy,
    originalUrl,
    openInPdfViewer,
    totalNrOfClicks,
    nrOfUniqueClicks,
    excludedClickerIds,
    additionalInfo,
    createdAt
  }) {
    return new StoreLink(
      id,
      generatedBy,
      originalUrl,
      openInPdfViewer,
      totalNrOfClicks,
      nrOfUniqueClicks,
      excludedClickerIds,
      typeof additionalInfo === 'string'
        ? JSON.parse(additionalInfo)
        : additionalInfo,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      generatedBy: this.generatedBy,
      originalUrl: this.originalUrl,
      openInPdfViewer: this.openInPdfViewer,
      totalNrOfClicks: this.totalNrOfClicks,
      nrOfUniqueClicks: this.nrOfUniqueClicks,
      excludedClickerIds: this.excludedClickerIds,
      additionalInfo: this.additionalInfo,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    generatedBy,
    originalUrl,
    openInPdfViewer,
    totalNrOfClicks,
    nrOfUniqueClicks,
    excludedClickerIds,
    additionalInfo,
    createdAt
  }) {
    return new StoreLink(
      id,
      generatedBy,
      originalUrl,
      openInPdfViewer,
      totalNrOfClicks,
      nrOfUniqueClicks,
      excludedClickerIds,
      additionalInfo,
      createdAt
    )
  }
}

export default StoreLink
